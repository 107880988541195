var windH = $(window).height();
var mobHeadH = $('#header').height();
var fMobH = windH - mobHeadH + 90; 

$('.mob-nav').css('height', fMobH);


$(document).ready(function(){
	$("#trigger-nav").click(function(){
		$(this).toggleClass('open');
		$('#mobile-nav').toggleClass('active');
		$('.mobile-header-baseline').toggleClass('opened');
		if ($('#mobile-nav').hasClass('active')) {
			//$('#mobile-nav').css('top', mobHeadH);
			$('html').addClass('lock-scroll');
		} else {
			//$('#mobile-nav').css('top', 0);
			$('html').removeClass('lock-scroll');
		}
	});
});