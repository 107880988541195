
function hideFilters() {
	if(!$('#filters').hasClass('closed')) {
		$('#filters').addClass('closed');
		$("#filters").animate({ scrollTop: 0 }, 0);
	}
	if(!$('.filters-toggle-arrow').hasClass('open')) {
		$('.filters-toggle-arrow').addClass('open');
	}
}

function initForm(){

	$('.filter-item-checkbox').click(function(){
		if($(this).is(':checked')){
			$(this).closest('.filter-item').addClass('active');
		}else{
			$(this).closest('.filter-item').removeClass('active');
		}

		$('.filters-search-btn').trigger('click');
	})

	$('.filters-form').submit(function(event){
		event.preventDefault();

		var data = $(this).serializeObject();
		var url = data.pageUrl;
		var lang = data.pageLang;
		data.lang = lang;

		if(!data.member){
			data.member = 0;
		}

		var $form = $(this);
		$('.loader-container').addClass('loading');
		$.ajax({
			method: 'POST',
			url: url,
			data: data
		})
		.done(function(response){
			if(response.list.length > 0){
				onSearchResult(response.list);
			}else{
				$('#mapModalEmpty').modal('show');
			}
		})
		.fail(function(d,text,error){//Thanks to automne json is not valid !
			if(text=='parsererror'){
				var raw = null;
				var code = 'raw='+d.responseText;
				eval(code);
				if(raw && raw.list){
					if(raw.list.length > 0){
						onSearchResult(raw.list);
					}else{
						$('#mapModalEmpty').modal('show');
					}
				}else{
					onSearchError('Cannot eval response : '+code);
				}
			}
		})
		.always(function(){
			$('.loader-container').removeClass('loading');
		});
	});
}

function onSearchError(text){
	console.error('Error occured : '+ text);
}

var markers = null;
var markerCluster = null;
google.maps.Map.prototype.clearMarkers = function() {
	for(var i=0; i < this.markers.length; i++){
		this.markers[i].setMap(null);
	}
	this.markers = new Array();
};

function onSearchResult(list){
	var map = window.map;

	if(!map){
		console.error('Google map is not loaded !');
	}

	infowindow = new google.maps.InfoWindow();
	if(markers != null && markers.length != 0){
		if(markerCluster){
			markerCluster.clearMarkers();
		}
		for(var m in markers){
			marker = markers[m];
			marker.setMap(null);
		}
	}


	markers = list.map(function(item,i){

		var location = {
			lat: parseFloat(item.lat),
			lng: parseFloat(item.lng)
			//Cluster testing values
			//lat:36.204824 + (Math.random() * (2) - 1),
			//lng:138.252924 + (Math.random() * (2) - 1)
		};

		var marker = new google.maps.Marker({
			map: map,
			position: location,
			title: item.main_company + ' / ' + item.name,
			icon: 'public/images/map/marqueur_orange.png'
			
		});


		var projectId 				= '',
		projectName 			= '',
		projectLocation 		= '',
		companyNamePrimary 		= '',
		//companyNameSecondary 	= '',
		sectors 				= '',
		activities 				= '',
		url 					= '',
		showLabel				= '',
		pictosContent			= '';

		projectId = item.project_id;

		projectName = item.name;
		projectLocation =item.country;
		if(item.city){
			projectLocation = item.city + ', ' + projectLocation;
			projectLocation = projectLocation.replace(' ,',',');
		}

		companyNamePrimary = item.main_company;
		/*if(item.sub_french_companies){
			companyNameSecondary = item.sub_french_companies;
		}*/

		if(item.sectors){
			sectors = item.sectors.join(', ');
		}

		if(item.activities){
			activities = item.activities.join(', ');	
		}

		if(item.showUrl){
			url = item.showUrl;
		}

		if(item.sectors_id){
			var tmpArray = [];
			for(var s in item.sectors_id){
				if (tmpArray.indexOf(item.sectors_id[s]) === -1) {
					id = item.sectors_id[s];
					tmpArray.push(id);
					pictosContent += '<div class="ser-project-picto-item"><span class="sectors-'+id+'"><i></i></span></div>';
				}
			}
		}

		if(item.showLabel){
			showLabel = item.showLabel;
		}

		if ( $('html').attr('lang') == 'fr' ) {
			var sectorTitle = 'Secteurs';
			var activityTitle = 'Activité';
		} else {
			var sectorTitle = 'Sectors';
			var activityTitle = 'Activity';
		};


		var content = '<div class="ser-project-infowindow" data-id="'+projectId+'">'
		+	'<div class="ser-project-head-container">'
		+		'<div class="ser-project-picto-container">'
		+			pictosContent
		+		'</div>'
		+		'<span class="ser-project-head-name">'
		+			projectName
		+		'</span>'
		+		'<span class="ser-project-head-location">'
		+			projectLocation
		+		'</span>'
		+		'<hr/>'
		+	'</div>'
		+	'<div class="ser-project-company-container">'
		+		'<p class="ser-project-company ser-project-company-primary">'
		+			companyNamePrimary
		+		'</p>'
		//+		'<p class="ser-project-company ser-project-company-secondary">'
		//+			companyNameSecondary
		//+		'</p>'
		+		'<hr/>'
		+	'</div>'
		+	'<div class="ser-project-sector-container">'
		+		'<p class="ser-project-sector-label">'
		+			sectorTitle
		+		'</p>'
		+		'<p class="ser-project-sector-value">'
		+			sectors
		+		'</p>'
		+		'<hr/>'
		+	'</div>'
		+	'<div class="ser-project-activity-container">'
		+		'<p class="ser-project-activity-label">'
		+			activityTitle
		+		'</p>'
		+		'<p class="ser-project-activity-value">'
		+			activities
		+		'</p>'
		+	'</div>'
		+	'<div class="ser-project-btn-container">'
		+		'<a href="'+url+'" class="ser-project-btn ser-project-btn-show">'+showLabel+'</a>'
		+	'</div>'
		+'</div>';

		content += '</div>';

		google.maps.event.addListener(marker,'click',function(){
			infowindow.setContent(content);

			if(!marker.open){
				infowindow.open(map,this);
				marker.open = true;
			}
			else{
				infowindow.close();
				marker.open = false;
			}
			hideFilters();
			$($('.gm-style-iw')[0]).parent('div').addClass('iw-cont');
		});

		
		google.maps.event.addListener(map, 'click', function() {
			infowindow.close();
			marker.open = false;
		});

		return marker;
	});

	google.maps.event.addListener(infowindow, 'domready', function() {

   // Reference to the DIV which receives the contents of the infowindow using jQuery
   var iwOuter = $('.gm-style-iw');

   /* The DIV we want to change is above the .gm-style-iw DIV.
    * So, we use jQuery and create a iwBackground variable,
    * and took advantage of the existing reference to .gm-style-iw for the previous DIV with .prev().
    */
    var iwBackground = iwOuter.prev();

  	// Remove the background shadow DIV
  	iwBackground.children(':nth-child(2)').css({'display' : 'none'});

  	// Remove the white background DIV
  	iwBackground.children(':nth-child(4)').css({'display' : 'none'});

  	// Moves the infowindow 115px to the right.
  	iwOuter.parent().parent().css({left: '0'});

  	// Dislpay none the arrow  
  	iwBackground.children(':nth-child(1)').attr('style', function(i,s){ return s + 'display: none !important;'});
  	iwBackground.children(':nth-child(3)').attr('style', function(i,s){ return s + 'display: none !important;'});

  	var iwCloseBtn = iwOuter.next();

	// Apply the desired effect to the close button
	iwCloseBtn.css({
	  opacity: '1', // by default the close button has an opacity of 0.7
	  right: '42px', 
	  top: '48px'
	});

	if (window.innerWidth < 480) {
		iwCloseBtn.css({'display': 'none'});
	};

	// The API automatically applies 0.7 opacity to the button after the mouseout event.
	// This function reverses this event to the desired value.
	iwCloseBtn.mouseout(function(){
		$(this).css({opacity: '1'});
	});

});

	if (!window.location.origin) {
		window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
	}

	var mcOptions = {
		styles:[{
			url: window.location.origin+'/public/images/pictos/cluster2.png',
			width: 60,
			height: 60,
			textSize: 14,
			textColor:"white"
		}]
	};
	
	//check if MarkerClusterer is defined and can be used has class
	if(typeof MarkerClusterer !== 'undefined'){
		markerCluster = new MarkerClusterer(map, markers, mcOptions);
	}else if(typeof markerClusterer !== 'undefined' && typeof markerClusterer.MarkerClusterer !== 'undefined'){
		markerCluster = new markerClusterer.MarkerClusterer({
			'map': map, 
			'markers': markers, 
			'renderer': {
				render: function ({ count, position }, stats) {
					return new google.maps.Marker({
						position,
						icon: {
							url: window.location.origin+'/public/images/pictos/cluster2.png',
							scaledSize: new google.maps.Size(60, 60),
						},
						label: {
							text: String(count),
							color: "white",
							fontSize: "14px",
						},
						// adjust zIndex to be above other markers
						zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
					});
				},
			}
		});
	}else{
		console.warn('MarkerClusterer is not defined, clustering will not be used');
	}

	if(markerCluster){
		google.maps.event.addListener(markerCluster, 'clusterclick', function(cluster) {
			hideFilters();
		});
	}

	map.SerReCenter();


}


$(document).ready(function(){
	initForm();

	$('.filters-form').trigger('submit');
	
	$('input[name="members"]').change(function(event) {
		$('.filters-form').trigger('submit');
	});

	$('.toggle.btn.ser-toggle').css('min-height', 24);
	$('.toggle.btn.ser-toggle').css('height', 24);
	$('.toggle.btn.ser-toggle').css('width', 50);
	$('.toggle.btn.ser-toggle').css('min-width', 50);
});