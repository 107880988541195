require("mobile-navigation");

var headerH = $('#header').height();

$('#main').css('padding-top', headerH);

function sameHeight(selector){
  var elements = document.querySelectorAll(selector);
  var content = document.querySelectorAll(selector);
  var h = 0;

  Array.prototype.forEach.call(elements, function(el, i){
    if (el.offsetHeight > h) {
      h = el.offsetHeight;
    }
  });
  Array.prototype.forEach.call(content, function(el, i){
    if(h != 0){
      el.style.height = h+'px';
    }
  });
};

$(document).ready(function() {
  if (window.innerWidth > 992) {
   sameHeight('.frcomps-sh');
   sameHeight('.glcont-sh');
 };
 if (window.innerWidth > 768) {
   sameHeight('.contactblk-sh');
 };
 $(".fancybox").fancybox();

 $('.flexslider').flexslider({
  controlNav: false,
  animation: "slide",
  move: 0,
  itemWidth: 255,
  itemMargin: 6,

});

});


$('.main-band-picto.picto-25').not(':eq(0)').hide();
$('.main-band-picto.picto-30').not(':eq(0)').hide();
$('.main-band-picto.picto-33').not(':eq(0)').hide();
$('.main-band-picto.picto-37').not(':eq(0)').hide();
$('.main-band-picto.picto-40').not(':eq(0)').hide();
$('.main-band-picto.picto-41').not(':eq(0)').hide();
$('.main-band-picto.picto-42').not(':eq(0)').hide();