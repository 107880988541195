require("mobile-navigation");

//Gestion des filtres

var headerH = $('#header').height();
var footerMapH = $('#footer-map').height();
var windowH = $(window).height();
var filtersH = windowH - headerH - footerMapH;
var windowW = $(window).width();
var filtersW = $('#filters').width();

var mapWidth = windowW - filtersW;
$('#filters').css('height', filtersH);
$('#filters').css('top', headerH);
$('#filters').css('bottom', footerMapH);

//$('#map').css('width',mapWidth);
$('#map').css('top',headerH);
$('#map').css('bottom',footerMapH);
$('#map').css('height',filtersH);

$( "#filters-toggle" ).click(function() {
	var filtersW = $('#filters').width();
	var mapWidth = windowW - filtersW;
	$('#filters').toggleClass('closed');
	$('.filters-toggle-arrow').toggleClass('open');
	if($('#filters-mobile-toggle').hasClass('cld') ) {
		$('#filters-mobile-toggle').removeClass('cld');
	} else {
		$('#filters-mobile-toggle').addClass('cld');
	}
	$("#filters").animate({ scrollTop: 0 }, 0);
});

$( "#filters-mobile-toggle" ).click(function() {
	var filtersW = $('#filters').width();
	var mapWidth = windowW - filtersW;
	$('#filters').toggleClass('closed');
	$(this).toggleClass('cld');
});

var DEFAULT_CENTER = {lat: 20, lng: 30};
var MIN_ZOOM = 2;
var MAX_ZOOM = 15;
var DEFAULT_ZOOM = 3;



function initMap() {
	window.map = new google.maps.Map(document.getElementById('map'), {
		center: DEFAULT_CENTER, 
		zoom: DEFAULT_ZOOM, 
		minZoom: MIN_ZOOM,
		maxZoom: MAX_ZOOM,
		streetViewControl: false,
		mapTypeControl: false,
		zoomControlOptions: {
			position: google.maps.ControlPosition.LEFT_BOTTOM
		},
		fullscreenControlOptions: {
			position: google.maps.ControlPosition.LEFT_TOP
		},
		styles: [
		{
			"featureType": "administrative",
			"stylers": [
			{
				"visibility": "on"
			}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels",
			"stylers": [
			{
				"visibility": "on"
			}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels.icon",
			"stylers": [
			{
				"color": "#ff9434"
			}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels.text",
			"stylers": [
			{
				"color": "#225ea9"
			},
			{
				"visibility": "on"
			}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels.text.stroke",
			"stylers": [
			{
				"color": "#d2e6ff"
			},
			{
				"weight": 2
			}
			]
		},
		{
			"featureType": "administrative.country",
			"elementType": "geometry.stroke",
			"stylers": [
			{
				"color": "#ffffff"
			}
			]
		},
		{
			"featureType": "water",
			"stylers": [
			{
				"color": "#c6dfff"
			}
			]
		}
		]
	});

	window.map.SerReCenter = function(){
		this.setCenter(DEFAULT_CENTER);
		this.setZoom(DEFAULT_ZOOM);
	}
}

$(document).ready(function() {
	initMap();	

	$('div[data-toggle^=toggle].ser-toggle').click(function() {
		if($('.ser-toggle-members').hasClass('activated')) {
			$('.ser-toggle-all').addClass('activated');
			$('.ser-toggle-members').removeClass('activated');
		} else {
			$('.ser-toggle-members').addClass('activated');
			$('.ser-toggle-all').removeClass('activated');
		}
	});

})