require("mobile-navigation");

var headerH = $('#header').height();

$('#main').css('padding-top', headerH);

function sameHeight(selector){
    var elements = document.querySelectorAll(selector);
    var content = document.querySelectorAll(selector);
    var h = 0;

    Array.prototype.forEach.call(elements, function(el, i){
        if (el.offsetHeight > h) {
            h = el.offsetHeight;
        }
    });
    Array.prototype.forEach.call(content, function(el, i){
        if(h != 0){
            el.style.height = h+'px';
        }
    });
};

function flexSlider(){
    var item = $('.home-partners .flexslider ul.slides li');
    var countItem = item.length;

    if(countItem > 4){
        
        if (window.matchMedia("(max-width: 600px)").matches) {

            $('.flexslider').flexslider({
                animation: "slide",
                controlNav : false,
                itemWidth: 300,
                itemMargin: 0,
                minItems : 1,
                maxItems : 1,
                prevText : "",
                nextText : ""
            });
            

        } else if (window.matchMedia("(max-width: 768px)").matches) {

            $('.flexslider').flexslider({
                animation: "slide",
                controlNav : false,
                itemWidth: 210,
                itemMargin: 0,
                minItems : 1,
                maxItems : 2,
                prevText : "",
                nextText : ""
            });
            
        }else if (window.matchMedia("(max-width: 992px)").matches) {

         $('.flexslider').flexslider({
            animation: "slide",
            controlNav : false,
            itemWidth: 210,
            itemMargin: 5,
            minItems : 2,
            maxItems : 3,
            prevText : "",
            nextText : ""
        });
         
     } else {
        $('.flexslider').flexslider({
                animation: "slide",
                controlNav : false,
                itemWidth: 210,
                itemMargin: 5,
                minItems : 2,
                maxItems : 4,
                prevText : "",
                nextText : ""
            });
     }

    } else {
        item.addClass('noFlex');
        if(countItem == 3){
            if (window.matchMedia("(max-width: 992px)").matches) {
                item.css({
                    'padding': '0 5%',
                    'border-left': '1px solid rgba(24, 56, 116, 0.5)'
                });
            } 
        }
    }

}

$(document).ready(function() {
    //if (window.innerWidth > 992) {
     sameHeight('.mosaik-sh');
   //};
});

$(window).on('load', function() {
        flexSlider();
});
$(window).on('resize', function() {
        flexSlider();
});