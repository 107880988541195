var MIN_ZOOM = 2;
var MAX_ZOOM = 12;
var DEFAULT_ZOOM = 3;

window.initMap = function (_lat, _lng) {
	var center = {lat: parseFloat(_lat), lng: parseFloat(_lng)};

	var map = new google.maps.Map(document.getElementById('pjmap'), {
		center: center,
		zoom: DEFAULT_ZOOM,
		minZoom: MIN_ZOOM,
		maxZoom: MAX_ZOOM,
		streetViewControl: false,
		mapTypeControl: false,
		 scrollwheel: false,
		zoomControlOptions: {
			position: google.maps.ControlPosition.LEFT_BOTTOM
		},
		fullscreenControlOptions: {
			position: google.maps.ControlPosition.LEFT_TOP
		},
		styles: [
		{
			"featureType": "administrative",
			"stylers": [
			{
				"visibility": "on"
			}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels",
			"stylers": [
			{
				"visibility": "on"
			}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels.icon",
			"stylers": [
			{
				"color": "#ff9434"
			}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels.text",
			"stylers": [
			{
				"color": "#225ea9"
			},
			{
				"visibility": "on"
			}
			]
		},
		{
			"featureType": "administrative",
			"elementType": "labels.text.stroke",
			"stylers": [
			{
				"color": "#d2e6ff"
			},
			{
				"weight": 2
			}
			]
		},
		{
			"featureType": "administrative.country",
			"elementType": "geometry.stroke",
			"stylers": [
			{
				"color": "#ffffff"
			}
			]
		},
		{
			"featureType": "water",
			"stylers": [
			{
				"color": "#c6dfff"
			}
			]
		}
		]
	});

	var marker = new google.maps.Marker({
		position: center,
		icon: 'public/images/map/marqueur_orange.png'
	});

	marker.setMap(map);
}